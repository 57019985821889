export const BASE_ITEM_REWARD = 40;
export const MOVE_COST = 0.5;

export const INTER_STEP_INTERVAL_MS = 90;
export const INTER_LEVEL_INTERVAL_MS = 4000;
export const INTER_SCENE_INTERVAL_MS = 500;
export const LINGER_ON_GOAL_MS = 1500;
export const OBJECT_FEATURE_MAP = {
  goalA: [0, 0],
  goalB: [1, 0],
  goalC: [0, 1],
  goalD: [1, 1],
};

export const OBJECT_KEY_TO_IDX = {
  goalA: 0,
  goalB: 1,
  goalC: 2,
  goalD: 3,
};
